import { faCode, faFile } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "./link";

export const ResumeLinks: readonly Link[] = [
  { link: "https://gitgetgot.dev", linkText: "gitgetgot.dev (Portfolio)", icon: faCode },
  { link: "https://github.com/snood1205", linkText: "GitHub", icon: faGithub },
  { link: "/assets/resume.pdf", linkText: "Resume (CV)", icon: faFile },
  { link: "https://www.linkedin.com/in/eli-sadoff-6506aa298/", linkText: "LinkedIn", icon: faLinkedinIn },
];
